import { ComponentProps } from "react"
import { Icon } from "../../components"
import { AttackModifierCard } from "../Card"
import SaveManager from "../SaveManager"
import Deck from "./Deck"

/** Class */

class AttackModifierDeck extends Deck<AttackModifierCard> {
  /** Keep track of the last ID given out so that we don't have duplicates when
   *  a card is removed. */
  protected idIncrement = 0

  constructor({
    id,
    icon,
    color,
  }: {
    id: string
    icon?: ComponentProps<typeof Icon>["type"]
    color?: string
  }) {
    super({
      id,
      cards: generateBaseDeck(id, icon, color),
    })
    this.idIncrement = this.cards.length
    this.addBlessing = this.addBlessing.bind(this)
    this.addCurse = this.addCurse.bind(this)
    this.addMinus1 = this.addMinus1.bind(this)
    this.removeBlessing = this.removeBlessing.bind(this)
    this.removeCurse = this.removeCurse.bind(this)
    this.removeMinus1 = this.removeMinus1.bind(this)
    this.includeCard = this.includeCard.bind(this)
    this.excludeCard = this.excludeCard.bind(this)
    this.shuffle = this.shuffle.bind(this)
    this.reset = this.reset.bind(this)
    this.save = this.save.bind(this)
  }

  get blessingCount() {
    return this.cards.filter(card => card.multiplier === 2 && card.temporary)
      .length
  }

  get curseCount() {
    return this.cards.filter(card => card.multiplier === 0 && card.temporary)
      .length
  }

  get minus1Count() {
    return this.allCards.filter(card => card.increment === -1 && card.temporary)
      .length
  }

  addCard(
    card: Omit<
      ConstructorParameters<typeof AttackModifierCard>[0],
      "id" | "color" | "icon"
    >,
    { exclude = false }: { exclude?: boolean } = {},
  ) {
    ;(exclude ? this.excludedCards : this.cards).push(
      new AttackModifierCard({
        ...card,
        id: this.id + "-" + this.idIncrement,
        color: this.allCards[0].color,
        icon: this.allCards[0].icon,
      }),
    )
    this.idIncrement++
    this.shuffle()
  }

  addExcludedCard(card: Parameters<AttackModifierDeck["addCard"]>[0]) {
    this.addCard(card, { exclude: true })
  }

  removeCard(id: string) {
    this.cards = this.cards.filter(card => card.id !== id)
    this.alertChange()
  }

  excludeCard(id: string) {
    const card = this.allCards.find(c => c.id === id)
    if (card) {
      this.excludedCards.push(card)
      this.cards = this.cards.filter(c => c.id !== id)
      this.drawnCards = this.drawnCards.filter(c => c.id !== id)
      this.alertChange()
    }
  }

  includeCard(id: string) {
    const card = this.excludedCards.find(c => c.id === id)
    if (card) {
      this.cards.push(card)
      this.excludedCards = this.excludedCards.filter(c => c.id !== id)
      this.shuffle()
    }
  }

  reset() {
    this.drawnCards = this.drawnCards.filter(
      c => !c.temporary || (c.temporary && c.increment === -1),
    )
    super.reset()
  }

  removeMinus1() {
    const index = this.cards.findIndex(
      card => card.increment === -1 && card.temporary,
    )
    if (index !== -1) {
      this.cards.splice(index, 1)
      this.alertChange()
    }
  }

  addMinus1() {
    this.cards.push(
      new AttackModifierCard({
        id: `${this.id}-minus1-${this.idIncrement}`,
        increment: -1,
        temporary: true,
        icon: this.allCards[0].icon,
        color: this.allCards[0].color,
      }),
    )
    this.idIncrement++
    this.shuffle()
  }

  removeCurse() {
    const index = this.cards.findIndex(
      card => card.multiplier === 0 && card.temporary,
    )
    if (index !== -1) {
      this.cards.splice(index, 1)
      this.alertChange()
    }
  }

  addCurse() {
    this.cards.push(
      new AttackModifierCard({
        id: `${this.id}-curse-${this.idIncrement}`,
        multiplier: 0,
        temporary: true,
        icon: this.allCards[0].icon,
        color: this.allCards[0].color,
      }),
    )
    this.idIncrement++
    this.shuffle()
  }

  removeBlessing() {
    const index = this.cards.findIndex(
      card => card.multiplier === 2 && card.temporary,
    )
    if (index !== -1) {
      this.cards.splice(index, 1)
      this.alertChange()
    }
  }

  addBlessing() {
    this.cards.push(
      new AttackModifierCard({
        id: `${this.id}-blessing-${this.idIncrement}`,
        multiplier: 2,
        temporary: true,
        icon: this.allCards[0].icon,
        color: this.allCards[0].color,
      }),
    )
    this.idIncrement++
    this.shuffle()
  }

  save() {
    return {
      ...super.save(),
      idIncrement: this.idIncrement,
      icon: this.allCards[0].icon,
      color: this.allCards[0].color,
    }
  }

  static load(data: ReturnType<AttackModifierDeck["save"]>) {
    const deck = new AttackModifierDeck({
      id: data.id,
      icon: data.icon,
      color: data.color,
    })
    deck.idIncrement = data.idIncrement
    deck.cards = data.cards.map(d => SaveManager.load<AttackModifierCard>(d))
    deck.excludedCards = data.excludedCards.map(d =>
      SaveManager.load<AttackModifierCard>(d),
    )
    deck.drawnCards = data.drawnCards.map(d =>
      SaveManager.load<AttackModifierCard>(d),
    )
    return deck
  }
}

/** Helpers */

/** Puts together an array of base cards that appear in every modifier deck. */
const generateBaseDeck = (
  id: string,
  icon?: ComponentProps<typeof Icon>["type"],
  color?: string,
) => {
  const newCard = (
    cardId: string,
    increment: number,
    multiplier = 1,
    triggersShuffle = false,
  ) =>
    new AttackModifierCard({
      id: `${id}-${cardId}`,
      multiplier,
      increment,
      icon,
      color,
      startingCard: true,
      triggersShuffle,
    })

  return [
    ...Array(5)
      .fill(null)
      .map((_, i) => newCard(`minus1-${i}`, -1)),
    ...Array(6)
      .fill(null)
      .map((_, i) => newCard(`neutral-${i}`, 0)),
    ...Array(5)
      .fill(null)
      .map((_, i) => newCard(`plus1-${i}`, 1)),
    newCard("plus2", 2),
    newCard("minus2", -2),
    newCard("double-0", 0, 2, true),
    newCard("miss-0", 0, 0, true),
  ]
}

/** Exports */

export default AttackModifierDeck
