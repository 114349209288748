import { icons, IconType } from "../../components/Icon"
import Card from "./Card"

/** Types */

type MonsterStats = {
  level: number
  normal: {
    health: number
    move: number
    attack: number
    range: number
    attributes: string[]
  }
  elite: {
    health: number
    move: number
    attack: number
    range: number
    attributes: string[]
  }
}

/** Class */

class MonsterAbilityCard extends Card {
  /** The initiative for the monster's action. */
  initiative: number
  /** The text of the card, using variables where necessary. */
  text: string
  /** The stats of the given monster that should be represented by the card. */
  stats: MonsterStats[]
  /** The AOE for the card. */
  aoe?: IconType

  constructor({
    id,
    initiative,
    triggersShuffle = false,
    image,
    text,
    stats,
    aoe,
  }: Pick<
    MonsterAbilityCard,
    "id" | "initiative" | "image" | "text" | "stats" | "aoe"
  > &
    Partial<Pick<MonsterAbilityCard, "triggersShuffle">>) {
    super({ id, triggersShuffle, image, text })
    this.initiative = initiative
    this.text = text
    this.stats = stats
    this.aoe = aoe
  }

  save() {
    return {
      ...super.save(),
      initiative: this.initiative,
      text: this.text,
      stats: this.stats,
      aoe: this.aoe,
    }
  }

  static load(card: ReturnType<MonsterAbilityCard["save"]>) {
    return new MonsterAbilityCard(card)
  }

  textHtml(level: number) {
    const eliteText = (s: string | number) => `</p><p class="elite">${s}</p><p>`
    const stats = this.stats[level]
    const iconRegex = new RegExp(
      `\\$(${Object.keys(icons).join("|")})(-?\\d+)?\\b`,
      "g",
    )
    const text = this.text
      .replace(/,/g, "<br/>")
      .replace(
        /\$m(?:ove)?(-?\d+)/g,
        (_, n) =>
          `${icons.move}${eliteText(stats.elite.move + Number(n))}/${
            stats.normal.move + Number(n)
          }`,
      )
      .replace(
        /\$a(?:ttack)?(-?\d+)r?(-?\d+)?/g,
        (_, n, r) =>
          `${icons.attack}${eliteText(stats.elite.attack + Number(n))}/${
            stats.normal.attack + Number(n)
          }${
            r || stats.elite.range > 1
              ? ` ${icons.range}${eliteText(
                  stats.elite.range + (r ? Number(r) : 0),
                )}/${stats.normal.range + (r ? Number(r) : 0)}`
              : ""
          }`,
      )
      .replace(
        iconRegex,
        (_, icon, qty) =>
          `${icons[icon as keyof typeof icons]}${qty ? ` ${Number(qty)}` : ""}`,
      )
      .replace(/\$healself(\d+)/g, `${icons.heal}$1 Self`)
    return `<p>${text}</p>`
  }
}

/** Exports */

export default MonsterAbilityCard
