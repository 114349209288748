import React, { ComponentProps } from "react"
import styled from "styled-components"
import blessing from "../assets/images/attackModifiers/blessing.png"
import curse from "../assets/images/attackModifiers/curse.png"
import double from "../assets/images/attackModifiers/double.png"
import minus1 from "../assets/images/attackModifiers/minus1.png"
import minus2 from "../assets/images/attackModifiers/minus2.png"
import miss from "../assets/images/attackModifiers/miss.png"
import neutral from "../assets/images/attackModifiers/neutral.png"
import plus1 from "../assets/images/attackModifiers/plus1.png"
import plus2 from "../assets/images/attackModifiers/plus2.png"
import { AttackModifierCard } from "../models/Card"
import Card from "./Card"
import Icon, { IconType } from "./Icon"

/** Types */

type Props = Omit<ComponentProps<typeof Card>, "children"> & {
  /** The card that should be rendered. */
  card: AttackModifierCard
}

/** Constants */

/** Component */

const UIAttackModifierCard = ({ card, ...props }: Props) => {
  const isEffectOnly = card.effect && !card.increment
  const effectMultiplierText =
    card.effectCount > 1 ? `x${card.effectCount}` : null

  return (
    <Card card={card} {...props}>
      <Front
        style={{
          gridTemplateRows:
            !isEffectOnly &&
            card.effect &&
            effectMultiplierText &&
            (card.rolling || card.triggersShuffle)
              ? "repeat(3, 1fr)"
              : "repeat(2, 1fr)",
        }}
      >
        {isEffectOnly ? (
          <Icon
            className='main'
            type={card.effect as IconType}
            width='100%'
            height='100%'
          />
        ) : (
          <img className='main' src={getCardModifierSrc(card)} />
        )}

        {!isEffectOnly && card.effect ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon type={card.effect} height='100%' width='100%' />
            {effectMultiplierText && <div>{effectMultiplierText}</div>}
          </div>
        ) : (
          effectMultiplierText && <div>{effectMultiplierText}</div>
        )}

        {card.triggersShuffle && (
          <Icon className='shuffle' type='shuffle' height='100%' width='100%' />
        )}
        {card.rolling && (
          <Icon className='rolling' type='roll' height='100%' width='100%' />
        )}
      </Front>

      <div style={{ background: card.color }}>
        {card.icon && <Icon type={card.icon} height='100%' width='100%' />}
      </div>
    </Card>
  )
}

/** GraphQL */

/** Styles */

const Front = styled.div`
  background: #666666;
  justify-content: center;
  display: grid;
  padding: 6px;
  align-items: center;
  justify-items: center;
  grid-auto-flow: column;
  grid-gap: 4px;
  max-height: 100%;
  font-size: 14px;

  & .main {
    grid-row: 1/-1;
    height: 100%;
  }

  & .rolling,
  & .shuffle {
    grid-row: span 1/-1;
  }
`

/** Hooks */

/** Helpers */

const getCardModifierSrc = (card: AttackModifierCard) => {
  if (card.temporary) {
    if (card.multiplier === 2) return blessing
    if (card.increment === -1) return minus1
    return curse
  }
  switch (card.multiplier) {
    case 2:
      return double
    case 0:
      return miss
  }
  switch (card.increment) {
    case 1:
      return plus1
    case 2:
      return plus2
    case -1:
      return minus1
    case -2:
      return minus2
  }
  return neutral
}

/** Exports */

export default UIAttackModifierCard
