import { generateModifierDeck, generateMonsterDeck } from "../helpers"
import { AttackModifierDeck } from "./Deck"
import MonsterAbilityDeck from "./Deck/MonsterAbilityDeck"
import SaveManager from "./SaveManager"

/** Class */

class AppData {
  monsterDeck: AttackModifierDeck
  playerDecks: AttackModifierDeck[]
  monsters: MonsterAbilityDeck[]

  constructor() {
    this.save = this.save.bind(this)
    this.monsterDeck = generateModifierDeck("monster")
    this.playerDecks = [
      generateModifierDeck("sun"),
      generateModifierDeck("tinkerer"),
      generateModifierDeck("scoundrel"),
    ]
    this.monsters = [
      generateMonsterDeck("Giant Viper"),
      generateMonsterDeck("Living Bones"),
      generateMonsterDeck("Harrower Infester"),
      generateMonsterDeck("Night Demon"),
      // generateMonsterDeck("Inox Archer"),
      // generateMonsterDeck("Inox Guard"),
      // generateMonsterDeck("Inox Shaman"),
    ]
  }

  save() {
    try {
      const data = {
        playerDecks: this.playerDecks.map(deck => deck.save()),
        monsters: {
          modifiers: this.monsterDeck.save(),
          abilities: this.monsters.map(deck => deck.save()),
        },
      }
      Object.keys(data).forEach(key => {
        localStorage.setItem(
          key,
          JSON.stringify(data[key as keyof typeof data]),
        )
      })
      return data
    } catch (e) {
      throw new Error("Error saving data")
    }
  }

  static load() {
    try {
      const playerDecks = JSON.parse(
        localStorage.getItem("playerDecks") || "null",
      ) as ReturnType<AppData["save"]>["playerDecks"]
      const monsters = JSON.parse(
        localStorage.getItem("monsters") || "null",
      ) as ReturnType<AppData["save"]>["monsters"]
      const appData = new AppData()
      if (!!playerDecks) {
        appData.playerDecks = playerDecks.map(deck => SaveManager.load(deck))
      }
      if (!!monsters) {
        if (!!monsters.modifiers) {
          appData.monsterDeck = SaveManager.load(monsters.modifiers)
        }
        if (!!monsters.abilities) {
          appData.monsters = monsters.abilities.map(deck =>
            SaveManager.load(deck),
          )
        }
      }
      return appData
    } catch (e) {
      throw e
    }
  }
}

/** Exports */

export default AppData
