import { motion } from "framer-motion"
import React, { ReactNode } from "react"
import styled from "styled-components"

/** Types */

type Props = {
  /** The content of the Modal. */
  children?: ReactNode
  /** The callback that should trigger when the close button is pressed. */
  onClose?: () => void
}

/** Constants */

/** Component */

const Modal = ({ children, onClose }: Props) => {
  return (
    <Frame
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      exit={{ y: "100%", transition: { type: "tween", duration: 0.1 } }}
    >
      <CloseButton onClick={onClose}>X</CloseButton>
      {children}
    </Frame>
  )
}

/** GraphQL */

/** Styles */

const Frame = styled(motion.div)`
  position: absolute;
  top: calc(8px + var(--safe-area-top));
  bottom: calc(8px + var(--safe-area-bottom));
  left: calc(8px + var(--safe-area-left));
  right: calc(8px + var(--safe-area-right));
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  overflow-y: auto;
  border-radius: 4px;
  padding: 8px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.onBackground};
  cursor: pointer;
  width: 36px;
  box-sizing: content-box;
  text-align: center;
`

/** Hooks */

/** Helpers */

/** Exports */

export default Modal
