import { DefaultTheme } from "styled-components"

/** Constants */

const defaultTheme: DefaultTheme = {
  colors: {
    background: "#232323",
    backgroundSecondary: "#303030",
    border: "#ebe8ea",
    text: {
      onBackground: "#ebe8ea",
    },
    classes: {
      angryFace: "#20C2EE",
      brute: "#4C7CBE",
      circles: "#F06DA2",
      cragheart: "#879640",
      cthulhu: "#6CC7BB",
      eclipse: "#A09ECD",
      lightningBolts: "#D64E51",
      mindthief: "#637B9B",
      musicNotes: "#E37E7C",
      saw: "#DFDDCC",
      scoundrel: "#A1D26F",
      spellweaver: "#B877B1",
      sun: "#F5C44A",
      threeSpears: "#DC8A36",
      tinkerer: "#C5B690",
      triforce: "#9F9E9E",
      twoMinis: "#AF745F",
    },
  },
}

/** Exports */

export default defaultTheme
