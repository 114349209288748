import { AnimatePresence } from "framer-motion"
import { useObservableState } from "observable-hooks"
import React from "react"
import styled from "styled-components"
import { UIMonsterAbilityCard } from "../components"
import MonsterAbilityDeck from "../models/Deck/MonsterAbilityDeck"

/** Types */

type Props = {
  /** The deck that's being shown. */
  deck: MonsterAbilityDeck
}

/** Constants */

/** Component */

const MonsterAbilityRow = ({ deck }: Props) => {
  useObservableState(deck.change$)

  return (
    <Row>
      <img src={deck.image} onClick={() => deck.draw()} />
      <div style={{ position: "relative", flexGrow: 1 }}>
        <AnimatePresence>
          {deck.drawnCards.map((card, idx) => (
            <UIMonsterAbilityCard
              transition={{ type: "tween", duration: 0.2 }}
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              key={card.id}
              card={card}
              onClick={() => deck.reset()}
            />
          ))}
        </AnimatePresence>
      </div>
    </Row>
  )
}

/** GraphQL */

/** Styles */

const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  max-height: 72px;
`

/** Hooks */

/** Helpers */

/** Exports */

export default MonsterAbilityRow
