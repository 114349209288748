import { motion, useMotionValue } from "framer-motion"
import { useObservable, useObservableEagerState } from "observable-hooks"
import React, { useContext, useEffect } from "react"
import { map, startWith } from "rxjs"
import styled from "styled-components"
import AppContext from "../AppContext"
import Icon from "../components/Icon"
import UIAttackModifierCard from "../components/UIAttackModifierCard"
import { AttackModifierCard } from "../models/Card"
import AttackModifierDeck from "../models/Deck/AttackModifierDeck"

/** Types */

type Props = {
  /** The deck whose cards should be displayed. */
  deck: AttackModifierDeck
}

/** Constants */

const CARD_GAP = 8
const CARD_WIDTH = 96

/** Component */

const ModifierDeckRow = ({ deck }: Props) => {
  const context = useContext(AppContext)

  const drawnCardCount = useObservableEagerState(
    useObservable(() =>
      deck.change$.pipe(
        map(() => deck.cards.length),
        startWith(0),
      ),
    ),
  )

  const width = useMotionValue(CARD_WIDTH + "px")

  width.set((CARD_WIDTH + CARD_GAP) * (drawnCardCount + 1) + "px")

  const cardIndex = [...deck.cards].reverse().reduce(
    (p, card, idx) => (
      (p[card.id] = {
        zIndex: idx - deck.cards.length,
        x: 0,
        rotateY: -180,
        card,
        onClick: () => deck.draw(),
      }),
      p
    ),
    [...deck.drawnCards].reverse().reduce(
      (p, card, idx) => (
        (p[card.id] = {
          zIndex: idx + 1,
          x: (idx + 1) * (CARD_WIDTH + CARD_GAP),
          rotateY: 0,
          card,
          onClick: () => deck.reset(),
        }),
        p
      ),
      {} as {
        [id: string]: {
          rotateY: number
          x: number
          zIndex: number
          card: AttackModifierCard
          onClick: () => void
        }
      },
    ),
  )

  useEffect(() => {
    deck.shuffle()
  }, [])

  return (
    <Row>
      <Frame>
        {Object.entries(cardIndex).map(
          ([id, { card, onClick, ...animate }]) => (
            <UIAttackModifierCard
              key={id}
              card={card}
              onClick={onClick}
              animate={{ ...animate, position: "absolute", y: 0 }}
              transition={{ type: "tween" }}
            />
          ),
        )}
      </Frame>
      <Icon
        type='cardSlot'
        height='50px'
        onClick={() => context.editDeck(deck)}
      />
    </Row>
  )
}

/** GraphQL */

/** Styles */

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  height: auto;
  grid-gap: 8px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
`

const Frame = styled(motion.div)`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 68px;
  z-index: 0;
`

/** Hooks */

/** Helpers */

/** Exports */

export default ModifierDeckRow
