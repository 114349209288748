import { ComponentProps } from "react"
import { Icon } from "../../components"

/** Class */

class Card {
  /** Whether or not the card will cause the deck to be shuffled at the end of
   *  the round. */
  triggersShuffle: boolean
  /** The unique identifier for the card. */
  id: string
  /** The text to display on the card. */
  text?: string
  /** The icon that should be displayed on the back of each card. */
  icon?: ComponentProps<typeof Icon>["type"]
  /** The color of the card back. */
  color?: string
  /** An image that should be displayed on the back of the card. */
  image?: string

  constructor({
    triggersShuffle = false,
    id,
    text,
    icon,
    color,
    image,
  }: Pick<
    Card,
    "color" | "icon" | "id" | "text" | "triggersShuffle" | "image"
  >) {
    this.triggersShuffle = triggersShuffle
    this.id = id
    this.text = text
    this.icon = icon
    this.color = color
    this.image = image
  }

  save() {
    return {
      className: this.constructor.name,
      id: this.id,
      text: this.text,
      icon: this.icon,
      color: this.color,
      triggersShuffle: this.triggersShuffle,
      image: this.image,
    }
  }

  static load(card: ReturnType<Card["save"]>) {
    return new Card(card)
  }
}

/** Exports */

export default Card
