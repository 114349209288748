import { createContext } from "react"
import { AttackModifierDeck } from "./models/Deck"

/** Context */

const AppContext = createContext({
  /** Starts editing a deck's contents. */
  editDeck: (deck: AttackModifierDeck | null) => {},
})

/** Exports */

export default AppContext
