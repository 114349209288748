import Card, { AttackModifierCard } from "../Card"
import MonsterAbilityCard from "../Card/MonsterAbilityCard"
import { AttackModifierDeck, default as Deck } from "../Deck"
import MonsterAbilityDeck from "../Deck/MonsterAbilityDeck"

/** Types */

/** Constants */

/** Class */

class SaveManager {
  static load<T>(data: unknown): T {
    try {
      switch ((data as Record<string, unknown>)?.className) {
        case "AttackModifierDeck":
          return AttackModifierDeck.load(
            data as ReturnType<AttackModifierDeck["save"]>,
          ) as unknown as T
        case "Deck":
          return Deck.load(data as ReturnType<Deck["save"]>) as unknown as T
        case "MonsterAbilityCard":
          return MonsterAbilityCard.load(
            data as ReturnType<MonsterAbilityCard["save"]>,
          ) as unknown as T
        case "MonsterAbilityDeck":
          return MonsterAbilityDeck.load(
            data as ReturnType<MonsterAbilityDeck["save"]>,
          ) as unknown as T
        case "Card":
          return Card.load(data as ReturnType<Card["save"]>) as unknown as T
        case "AttackModifierCard":
          return AttackModifierCard.load(
            data as ReturnType<AttackModifierCard["save"]>,
          ) as unknown as T
        default:
          throw new Error("Unknown save entity")
      }
    } catch (e) {
      throw new Error(`Failed to load entity: ${e.message}`)
    }
  }
}

/** Exports */

export default SaveManager
