import { IconType } from "../components/Icon"

/** Data */

const monsterAbilities: Record<
  GH.Monster,
  [initiative: number, text: string, reshuffle?: boolean, aoe?: IconType][]
> = {
  "Ancient Artillery": [],
  "Bandit Archer": [
    [56, "$a-1 $target2"],
    [
      14,
      "$m-1, $a-1, Create a 3 damage trap in an adjacent empty hex closest to an enemy.",
    ],
    [32, "$m0, $a1r-1"],
    [16, "$m1, $a-1"],
    [31, "$m0, $a0"],
    [44, "$m-1, $a1"],
    [68, "$a1r1", true],
    [29, "$m0, $a-1r1 $immobilize", true],
  ],
  "Bandit Guard": [
    [35, "$m-1, $a0 $range2"],
    [15, "$shield1, $a0 $poison", true],
    [15, "$shield1, $retaliate2", true],
    [55, "$m-1, $a0, $strengthen Self"],
    [70, "$m-1, $a1"],
    [50, "$m0, $a0"],
    [50, "$m0, $a0"],
    [30, "$m1, $a-1"],
  ],
  "Black Imp": [],
  "Cave Bear": [
    [14, "$m-1, $a-1 $immobilize"],
    [80, "$a-1, $m-2, $a-1 $wound", true],
    [3, "$shield1, $retaliate2, $healself2"],
    [34, "$a1 $wound", true],
    [13, "$m1, $a-1"],
    [60, "$m-1, $a1"],
    [41, "$m0, $a0"],
    [61, "$m0, $a-1 $target2"],
  ],
  "City Archer": [
    [56, "$a-1 $target2"],
    [
      14,
      "$m-1, $a-1, Create a 3 damage trap in an adjacent empty hex closest to an enemy.",
    ],
    [32, "$m0, $a1r-1"],
    [16, "$m1, $a-1"],
    [31, "$m0, $a0"],
    [44, "$m-1, $a1"],
    [68, "$a1r1", true],
    [29, "$m0, $a-1r1 $immobilize", true],
  ],
  "City Guard": [
    [35, "$m-1, $a0 $range2"],
    [15, "$shield1, $a0 $poison", true],
    [15, "$shield1, $retaliate2", true],
    [55, "$m-1, $a0, $strengthen Self"],
    [70, "$m-1, $a1"],
    [50, "$m0, $a0"],
    [50, "$m0, $a0"],
    [30, "$m1, $a-1"],
  ],
  Cultist: [],
  "Deep Terror": [],
  "Earth Demon": [],
  "Flame Demon": [],
  "Frost Demon": [
    [78, "$m-1, $a0, $ice", true, "melee3Triangle"],
    [78, "$m-1, $a0, $ice", true, "melee3Triangle"],
    [18, "$shield2, $m1, $consumeFire: Suffers 1 Damage"],
    [18, "$immobilize $target All Enemies in $range2, $consumeIce:$healself3"],
    [58, "$m-1, $a-1 $pierce3, $consumeWildElement:$wind"],
    [38, "$m1, $a-1"],
    [58, "$m0, $a0"],
    [58, "$m-1, $a0 $range2, $consumeIce:$attack+2 $range+1"],
  ],
  "Forest Imp": [],
  "Giant Viper": [
    [32, "$m0, $a0, $attack+2 if$target is adjacent to Viper's allies", true],
    [32, "$m0, $a0, $attack+2 if$target is adjacent to Viper's allies", true],
    [11, "$shield1, $a-1"],
    [43, "$m1 $jump, $a-1 $target All Adjacent Enemies"],
    [58, "$m-1, $a1"],
    [58, "$m1 $jump, $a-1, $roundBonus: $attack vs Viper gain Disadvantage"],
    [43, "$m-1 $jump, $a0 $target2"],
    [23, "$m-1, $a-1 $immobilize, $a-1"],
  ],
  "Harrower Infester": [
    [38, "$m-1, $a1 $target2"],
    [7, "$m0, $a-1 $poison, $dark"],
    [16, "$m-1, $a-1, $healself5"],
    [16, "$a2 $immobilize, $retaliate2"],
    [2, "$shield2, $retaliate2 $range3", true],
    [38, "$m0, $a-1 $target2, $consumeDark:$attack+2 $disarm"],
    [7, "$a-1r3 $muddle, $healself4", true],
    [
      30,
      "$m-1, $a0, $consumeDark:$healself2 for Each Target Damaged",
      false,
      "melee3Line",
    ],
  ],
  Hound: [],
  "Inox Archer": [
    [56, "$a-1 $target2"],
    [
      14,
      "$m-1, $a-1, Create a 3 damage trap in an adjacent empty hex closest to an enemy.",
    ],
    [32, "$m0, $a1r-1"],
    [16, "$m1, $a-1"],
    [31, "$m0, $a0"],
    [44, "$m-1, $a1"],
    [68, "$a1r1", true],
    [29, "$m0, $a-1r1 $immobilize", true],
  ],
  "Inox Guard": [
    [35, "$m-1, $a0 $range2"],
    [15, "$shield1, $a0 $poison", true],
    [15, "$shield1, $retaliate2", true],
    [55, "$m-1, $a0, $strengthen Self"],
    [70, "$m-1, $a1"],
    [50, "$m0, $a0"],
    [50, "$m0, $a0"],
    [30, "$m1, $a-1"],
  ],
  "Inox Shaman": [
    [89, "$m-1, $heal1 Affect All Adjacent Allies, $bless Self"],
    [9, "$m1, $a-1 $curse $target2"],
    [8, "$m0, $a-1 $disarm"],
    [8, "$m-1, $a0 $immobilize"],
    [74, "$m-1, $a1"],
    [62, "$m0, $a0"],
    [23, "$m0, $heal3 $range3", true],
    [23, "$m0, $heal3 $range3", true],
  ],
  "Living Bones": [
    [20, "$m-2, $a0, $healself2", true],
    [25, "$m1, $a-1"],
    [45, "$m0, $a0"],
    [45, "$m0, $a0"],
    [12, "$shield1, $healself2", true],
    [64, "$m-1, $a1"],
    [74, "$m0, $a0 $target One Enemy with All Attacks"],
    [81, "$a2"],
  ],
  "Living Corpse": [],
  "Living Spirit": [
    [33, "$m0, $a-1 $target All in Range", true],
    [61, "$a0r-1 $target2"],
    [75, "$m-1, $a1r-1, $healself1"],
    [48, "$m0, $a0"],
    [55, "$m0, $curse $target All in Range, $ice"],
    [22, "$m-1, $a-1 $muddle", true],
    [48, "$m0, $a0"],
    [67, "$m-1, $a1 $consumeIce:$stun"],
  ],
  Lurker: [],
  "Night Demon": [
    [26, "$a-2 $range3 $target3, $consumeDark:$muddle"],
    [7, "$m1, $a-1, $consumeDark:$invisible Self"],
    [4, "$m1, $a-1, $dark"],
    [35, "$a-1, $a-1 $pierce2, $consumeLight:$curse Self"],
    [22, "$m0, $a0, $dark"],
    [
      15,
      "$m0 $a-1, All Adjacent enemies and allies suffer 1 Damage, $consumeWildElement:$dark",
    ],
    [41, "$m-1, $a1, $dark", true],
    [46, "$m-1, $a1, $consumeDark: $attack+2"],
  ],
  Ooze: [],
  "Rending Drake": [],
  "Savvas Icestorm": [
    [70, "$push2 $target All Adjacent Enemies, $consumeWind:$push+2, $a1r1"],
    [98, "Summon Normal Wind Demon, $wind"],
    [98, "Summon Normal Frost Demon, $ice"],
    [19, "$m0, $a-1r-1 $ice, $shield1 Self and Allies in $range2"],
    [14, "$a0, $consumeIce:$attack+2 $immobilize, $retaliate2 $wind"],
    [14, "$shield4, $heal2 $range3 $consumeIce:$heal+3, $consumeIce:$a0"],
    [47, "$disarm $target All Adjacent Enemies, $m0, $a-1 $wind", true],
    [35, "$m-1, $a-1, $ice", true, "melee6Triangle"],
  ],
  "Savvas Lavaflow": [],
  "Spitting Drake": [
    [6, "$shield2, $heal2 Self, $strengthen Self"],
    [89, "$a-2 $stun"],
    [87, "$m-1, $a1"],
    [27, "$a0 $target2 $poison"],
    [52, "$m0, $a0"],
    [32, "$m1, $a-1"],
    [89, "$m-1, $a-2r-2 $poison", true, "aoe7Hex"],
    [57, "$m0, $a-1r-1", true, "aoe3Triangle"],
  ],
  "Stone Golem": [
    [11, "$retaliate3 $range3"],
    [28, "$m1, $a0, Suffers 1 Damage"],
    [51, "$m1, $a-1", true],
    [65, "$m0, $a0"],
    [72, "$a1r3, Suffers 2 Damage"],
    [90, "$m-1, $a1", true],
    [83, "$m0, $a-1 $target All Adjacent Enemies"],
    [28, "$m1, $a-2r3 $pull2 $immobilize"],
  ],
  "Sun Demon": [],
  "Vermling Scout": [],
  "Vermling Shaman": [
    [89, "$m-1, $heal1 Affect All Adjacent Allies, $bless Self"],
    [9, "$m1, $a-1 $curse $target2"],
    [8, "$m0, $a-1 $disarm"],
    [8, "$m-1, $a0 $immobilize"],
    [74, "$m-1, $a1"],
    [62, "$m0, $a0"],
    [23, "$m0, $heal3 $range3", true],
    [23, "$m0, $heal3 $range3", true],
  ],
  "Wind Demon": [
    [9, "$a-1, $healself1, $consumeWind:$invisible"],
    [21, "$m0, $a0 $pull1, $wind", true],
    [21, "$m0, $a0 $pull1, $wind", true],
    [29, "$m0, $a-1 $target2 $consumeWind:$push2"],
    [
      37,
      "$m0, $a0, $consumeWind:$attack+1 $blueHex",
      false,
      "melee7HexSide3Blue",
    ],
    [43, "$m-1, $a1 $consumeWind:$target2"],
    [
      43,
      "$push1 $target All Adjacent Enemies, $attack0 $consumeLeaf:$range -2",
    ],
    [2, "$shield1 $m-1, $a-1, $consumeWildElement:$wind"],
  ],
}

/** Exports */

export default monsterAbilities
