import { useObservableState } from "observable-hooks"
import React, { useContext } from "react"
import styled from "styled-components"
import AppContext from "../AppContext"
import UIAttackModifierCard from "../components/UIAttackModifierCard"
import { AttackModifierCard } from "../models/Card"
import { AttackModifierDeck } from "../models/Deck"
import { Modal } from "../ui"

/** Types */

type Props = {
  /** The deck that should be edited. */
  deck: AttackModifierDeck
}

/** Constants */

const TEMPORARY_CARDS_DISPLAY: AttackModifierCard[] = [
  new AttackModifierCard({
    color: "blue",
    icon: "addMinus1",
    id: "bless",
    multiplier: 2,
    temporary: true,
  }),
  new AttackModifierCard({
    color: "blue",
    icon: "addMinus1",
    id: "curse",
    multiplier: 0,
    temporary: true,
  }),
  new AttackModifierCard({
    color: "blue",
    icon: "addMinus1",
    id: "minus1",
    increment: -1,
    temporary: true,
  }),
]

/** Component */

const EditCardsModal = ({ deck }: Props) => {
  const context = useContext(AppContext)

  useObservableState(deck.change$)

  const allCards = [...deck.allCards, ...deck.excludedCards]
    .filter(card => !card.temporary)
    .sort((a, b) => (a.id < b.id ? -1 : 1))
  const startingCards = allCards.filter(card => card.startingCard)
  const classCards = allCards.filter(card => !card.startingCard)
  const allCardIds = deck.allCards.map(card => card.id)

  const toggleCard = (card: AttackModifierCard) => {
    if (allCardIds.includes(card.id)) {
      deck.excludeCard(card.id)
    } else {
      deck.includeCard(card.id)
    }
  }

  return (
    <Modal onClose={() => context.editDeck(null)}>
      {deck && (
        <DeckGrid>
          <div style={{ gridColumn: "1/-1", fontSize: 48 }}>{"Edit Cards"}</div>

          {TEMPORARY_CARDS_DISPLAY.map((card, i) => (
            <React.Fragment key={card.id}>
              <UIAttackModifierCard card={card} />

              <div
                style={{
                  gridColumn: "2/-1",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  fontSize: 36,
                }}
              >
                <div
                  style={{
                    boxSizing: "content-box",
                    width: 48,
                    textAlign: "center",
                  }}
                  onClick={() =>
                    [deck.removeBlessing, deck.removeCurse, deck.removeMinus1][
                      i
                    ]()
                  }
                >
                  -
                </div>
                <div>
                  {[deck.blessingCount, deck.curseCount, deck.minus1Count][i]}
                </div>
                <div
                  style={{
                    boxSizing: "content-box",
                    width: 48,
                    textAlign: "center",
                  }}
                  onClick={() =>
                    [deck.addBlessing, deck.addCurse, deck.addMinus1][i]()
                  }
                >
                  +
                </div>
              </div>
            </React.Fragment>
          ))}

          <div style={{ gridColumn: "1/-1" }}>STARTING CARDS</div>
          {startingCards.map(card => (
            <UIAttackModifierCard
              key={card.id}
              card={card}
              style={{ opacity: allCardIds.includes(card.id) ? 1.0 : 0.4 }}
              onClick={() => toggleCard(card)}
            />
          ))}
          <div style={{ gridColumn: "1/-1" }}>CLASS CARDS</div>
          {classCards.map(card => (
            <UIAttackModifierCard
              key={card.id}
              card={card}
              style={{ opacity: allCardIds.includes(card.id) ? 1.0 : 0.4 }}
              onClick={() => toggleCard(card)}
            />
          ))}
        </DeckGrid>
      )}
    </Modal>
  )
}

/** GraphQL */

/** Styles */

const DeckGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
  grid-gap: 8px;
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
  min-width: 100%;
`

/** Hooks */

/** Helpers */

/** Exports */

export default EditCardsModal
