import { motion, MotionProps } from "framer-motion"
import React from "react"
import styled from "styled-components"
import MonsterAbilityCard from "../models/Card/MonsterAbilityCard"
import Icon from "./Icon"

/** Types */

type Props = {
  /** The ability card. */
  card: MonsterAbilityCard
  /** Callback that should be triggered when the card is clicked. */
  onClick?: () => void
}

/** Constants */

/** Component */

const UIMonsterAbilityCard = ({
  card,
  onClick,
  ...rest
}: Props & MotionProps) => {
  const placeholder = true

  return (
    <Frame onClick={onClick} {...rest}>
      <div className='initiative'>{card.initiative}</div>
      {card.triggersShuffle && (
        <Icon className='shuffle' type='shuffle' width='16px' height='16px' />
      )}
      <div
        className='text'
        dangerouslySetInnerHTML={{
          __html: card.textHtml(2),
        }}
      />
      {card.aoe && (
        <Icon
          type={card.aoe}
          style={{
            gridColumn: "3",
            gridRow: "1/-1",
            width: 64,
          }}
        />
      )}
    </Frame>
  )
}

/** GraphQL */

/** Styles */

const Frame = styled(motion.div)`
  background-color: maroon;
  /* border: 1px solid white; */
  border-radius: 8px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr auto;
  grid-gap: 4px;
  padding: 4px 8px;

  & .shuffle svg,
  & .text svg {
    display: inline;
    height: 20px;
    width: 20px;
    vertical-align: text-top;
  }

  & .shuffle {
    grid-column: 1;
    grid-row: 2;
  }

  & .initiative {
    font-size: 28px;
  }

  & .text {
    grid-column: 2;
    grid-row: 1/-1;
    font-size: 18px;
    text-align: center;
    line-height: 0.9;
    max-height: 100%;
  }

  & .text svg {
    margin-left: 8px;
  }

  & .aoe {
    grid-column: 3;
    grid-row: 1/-1;
    height: 100%;
  }

  & p {
    display: inline;
  }

  & p.elite {
    color: ${({ theme }) => theme.colors.classes.sun};
  }
`

/** Hooks */

/** Helpers */

/** Exports */

export default UIMonsterAbilityCard
