import { AnimatePresence } from "framer-motion"
import { useSubscription } from "observable-hooks"
import React, { useState } from "react"
import { asapScheduler, debounceTime, merge } from "rxjs"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import AppContext from "./AppContext"
import { AppData } from "./models"
import Card from "./models/Card"
import { AttackModifierDeck } from "./models/Deck"
import { EditCardsModal, ModifierDeckRow } from "./panels"
import MonsterAbilityRow from "./panels/MonsterAbilityRow"
import * as theme from "./theme"

/** Types */

/** Constants */

const appData = AppData.load()
const deckChange$ = merge(
  appData.monsterDeck.change$,
  ...appData.playerDecks.map(deck => deck.change$),
  ...appData.monsters.map(deck => deck.change$),
).pipe(debounceTime(1000, asapScheduler))

/** Component */

const App = () => {
  const [editingDeck, setEditingDeck] = useState<AttackModifierDeck | null>(
    null,
  )

  useSubscription(deckChange$, appData.save)

  return (
    <ThemeProvider theme={theme.gloomy}>
      <AppContext.Provider
        value={{
          editDeck: deck => {
            setEditingDeck(deck)
          },
        }}
      >
        <GlobalStyle />
        <Frame>
          <Toolbar>
            <button
              onClick={() => {
                localStorage.removeItem("saveData")
                localStorage.removeItem("monsters")
                location.reload()
              }}
            >
              Reset
            </button>
            <button
              onClick={() =>
                [
                  appData.monsterDeck,
                  ...appData.playerDecks,
                  ...appData.monsters,
                ].forEach(deck => {
                  if (
                    (deck.drawnCards as Card[]).find(
                      card => card.triggersShuffle,
                    )
                  ) {
                    deck.reset()
                  }
                })
              }
            >
              Next Round
            </button>
          </Toolbar>
          {appData.monsters.map(monster => (
            <MonsterAbilityRow key={monster.id} deck={monster} />
          ))}
          <ModifierDeckRow deck={appData.monsterDeck} />
          {appData.playerDecks.map(deck => (
            <ModifierDeckRow key={deck.id} deck={deck} />
          ))}
        </Frame>
        <AnimatePresence>
          {editingDeck && <EditCardsModal deck={editingDeck} />}
        </AnimatePresence>
      </AppContext.Provider>
    </ThemeProvider>
  )
}

/** GraphQL */

/** Styles */

const GlobalStyle = createGlobalStyle`
  html, body {
    color: ${({ theme }) => theme.colors.text.onBackground};
    background: ${({ theme }) => theme.colors.background};
    position: absolute;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
  }

  body {
    padding-left: var(--safe-area-left, 0px);
    padding-right: var(--safe-area-right, 0px);
    padding-top: var(--safe-area-top, 0px);
    padding-bottom: var(--safe-area-bottom, 0px);
  }
`

const Frame = styled.div`
  display: grid;
  grid-gap: 8px;
  padding: 8px;
`

const Divider = styled.div`
  width: 80%;
  height: 1px;
  background: ${({ theme }) => theme.colors.text.onBackground};
  justify-self: center;
`

const Toolbar = styled.div`
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  gap: 8px;
  padding: 8px;
`

/** Hooks */

/** Helpers */

/** Exports */

export default App
