import { asapScheduler, auditTime, share, Subject } from "rxjs"
import Card from "../Card"
import SaveManager from "../SaveManager"

/** Class */

class Deck<T extends Card = Card> {
  /** The unique identifier for the deck. */
  id: string
  /** The cards in the deck that are ready to be drawn. */
  cards: T[]
  /** The cards in teh deck that have already been drawn. */
  drawnCards: T[] = []
  /** The cards that have been removed temporarily from the deck and should not
   *  be shuffled back in. */
  excludedCards: T[] = []

  /** Emits any time the deck's cards change places or something is added. */
  _change$ = new Subject<number>()
  /** Emits any time the deck's cards change places or something is added. The
   *  signals are batched to prevent unnecessary redundant emissions. */
  change$ = this._change$.pipe(auditTime(0, asapScheduler), share())

  constructor({ cards, id }: Pick<Deck<T>, "id" | "cards">) {
    this.id = id
    this.cards = cards
    this.shuffle = this.shuffle.bind(this)
    this.draw = this.draw.bind(this)
    this.return = this.return.bind(this)
    this.reset = this.reset.bind(this)
    this.save = this.save.bind(this)
  }

  get allCards() {
    return this.cards.concat(this.drawnCards)
  }

  protected alertChange() {
    this._change$.next(Date.now())
  }

  shuffle() {
    this.cards = this.cards.sort(() => Math.random() - 0.5)
    this.alertChange()
  }

  draw(numCards: number = 1) {
    const drawnCards = this.cards.splice(0, numCards)
    this.drawnCards.push(...drawnCards)
    this.alertChange()
    return drawnCards
  }

  return(cards: T[]) {
    this.cards.push(...cards)
    this.drawnCards = this.drawnCards.filter(card => !cards.includes(card))
    this.alertChange()
  }

  reset() {
    this.cards = this.cards.concat(this.drawnCards)
    this.drawnCards = []
    this.shuffle()
    this.alertChange()
  }

  save() {
    return {
      className: this.constructor.name,
      id: this.id,
      cards: this.cards.map(card => card.save()),
      excludedCards: this.excludedCards.map(card => card.save()),
      drawnCards: this.drawnCards.map(card => card.save()),
    }
  }

  static load(data: ReturnType<Deck<Card>["save"]>) {
    const deck = new Deck({
      id: data.id,
      cards: data.cards.map(d => SaveManager.load<Card>(d)),
    })
    deck.excludedCards = data.excludedCards.map(d => SaveManager.load<Card>(d))
    deck.drawnCards = data.drawnCards.map(d => SaveManager.load<Card>(d))
    return deck
  }
}

/** Exports */

export default Deck
