import { IconType } from "../../components/Icon"
import { monsterStats } from "../../data"
import MonsterAbilityCard from "../Card/MonsterAbilityCard"
import SaveManager from "../SaveManager"
import Deck from "./Deck"

/** Class */

class MonsterAbilityDeck extends Deck<MonsterAbilityCard> {
  /** Keep track of the last ID given out so that we don't have duplicates when
   *  a card is removed. */
  protected idIncrement = 0
  /** The name of the monster. */
  name: string
  /** The image of the monster, as a src URL. */
  image: string

  constructor({
    id,
    name,
    cards,
  }: {
    id: string
    name: string
    /** The list of ability cards that should be used, in initiative/ability
     *  text/shuffle tuples. */
    cards: [
      initiative: number,
      text: string,
      reshuffle?: boolean,
      aoe?: IconType,
    ][]
  }) {
    super({ id, cards: [] as MonsterAbilityCard[] })
    this.name = name
    this.image = `images/monsters/${name.toLowerCase().replace(" ", "-")}.png`
    cards.forEach(([initiative, text, shuffle = false, aoe]) =>
      this.addCard(initiative, text, shuffle, aoe),
    )
  }

  addCard(initiative: number, text: string, shuffle = false, aoe?: IconType) {
    const card = new MonsterAbilityCard({
      id: `${this.id}-${this.idIncrement}`,
      initiative,
      text,
      image: this.image,
      stats: monsterStats.monsters[this.name as GH.Monster]?.level,
      triggersShuffle: shuffle,
      aoe,
    })
    this.cards.push(card)
    this.idIncrement++
    this.shuffle()
  }

  save() {
    return {
      ...super.save(),
      idIncrement: this.idIncrement,
      name: this.name,
      image: this.image,
    }
  }

  static load(data: ReturnType<MonsterAbilityDeck["save"]>) {
    const deck = new MonsterAbilityDeck({
      id: data.id,
      name: data.name,
      cards: [],
    })
    deck.drawnCards = data.drawnCards.map(d =>
      SaveManager.load<MonsterAbilityCard>(d),
    )
    deck.cards = data.cards.map(d => SaveManager.load<MonsterAbilityCard>(d))
    deck.idIncrement = data.idIncrement
    return deck
  }
}

/** Helpers */

/** Exports */

export default MonsterAbilityDeck
