const monsterStats = {
  monsters: {
    "Ancient Artillery": {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 0,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 0,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 0,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 0,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 0,
            attack: 2,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 0,
            attack: 3,
            range: 6,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 0,
            attack: 3,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 0,
            attack: 4,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 0,
            attack: 4,
            range: 6,
            attributes: ["Target 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 0,
            attack: 4,
            range: 7,
            attributes: ["Target 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [],
          },
          elite: {
            health: 16,
            move: 0,
            attack: 5,
            range: 7,
            attributes: ["Target 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 0,
            attack: 4,
            range: 7,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 0,
            attack: 5,
            range: 7,
            attributes: ["Target 2"],
          },
        },
      ],
    },
    "Bandit Archer": {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 3,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 6,
            attributes: ["Poison"],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 4,
            attack: 4,
            range: 6,
            attributes: ["Poison"],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 4,
            attack: 5,
            range: 6,
            attributes: ["Poison"],
          },
        },
        {
          level: 7,
          normal: {
            health: 13,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 4,
            attack: 5,
            range: 6,
            attributes: ["Poison"],
          },
        },
      ],
    },
    "Bandit Guard": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Shield 2"],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Muddle", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Muddle", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Muddle", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Muddle", "Shield 3"],
          },
        },
      ],
    },
    "Black Imp": {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 1,
            attack: 1,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 4,
            move: 1,
            attack: 2,
            range: 3,
            attributes: ["Poison"],
          },
        },
        {
          level: 1,
          normal: {
            health: 4,
            move: 1,
            attack: 1,
            range: 3,
            attributes: ["Poison"],
          },
          elite: {
            health: 6,
            move: 1,
            attack: 2,
            range: 3,
            attributes: ["Poison"],
          },
        },
        {
          level: 2,
          normal: {
            health: 5,
            move: 1,
            attack: 1,
            range: 4,
            attributes: ["Poison"],
          },
          elite: {
            health: 8,
            move: 1,
            attack: 2,
            range: 4,
            attributes: ["Poison"],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 1,
            attack: 2,
            range: 4,
            attributes: ["Poison"],
          },
          elite: {
            health: 8,
            move: 1,
            attack: 3,
            range: 4,
            attributes: ["Attackers gain Disadvantage", "Poison"],
          },
        },
        {
          level: 4,
          normal: {
            health: 7,
            move: 1,
            attack: 2,
            range: 4,
            attributes: ["Poison"],
          },
          elite: {
            health: 11,
            move: 1,
            attack: 3,
            range: 4,
            attributes: ["Attackers gain Disadvantage", "Poison"],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 1,
            attack: 2,
            range: 4,
            attributes: ["Poison"],
          },
          elite: {
            health: 12,
            move: 1,
            attack: 3,
            range: 5,
            attributes: ["Attackers gain Disadvantage", "Poison"],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 1,
            attack: 3,
            range: 4,
            attributes: ["Poison"],
          },
          elite: {
            health: 14,
            move: 1,
            attack: 4,
            range: 5,
            attributes: ["Attackers gain Disadvantage", "Poison"],
          },
        },
        {
          level: 7,
          normal: {
            health: 12,
            move: 1,
            attack: 3,
            range: 4,
            attributes: ["Poison"],
          },
          elite: {
            health: 17,
            move: 1,
            attack: 4,
            range: 5,
            attributes: ["Attackers gain Disadvantage", "Poison"],
          },
        },
      ],
    },
    "Cave Bear": {
      level: [
        {
          level: 0,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 11,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 13,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 16,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 21,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 5,
          normal: {
            health: 17,
            move: 5,
            attack: 4,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 24,
            move: 5,
            attack: 6,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 6,
          normal: {
            health: 19,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 28,
            move: 5,
            attack: 7,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 7,
          normal: {
            health: 22,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 33,
            move: 5,
            attack: 7,
            range: 0,
            attributes: ["Wound"],
          },
        },
      ],
    },
    "City Archer": {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 1,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 1,
            attack: 3,
            range: 5,
            attributes: ["Pierce 1", "Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 1,
            attack: 4,
            range: 5,
            attributes: ["Pierce 2", "Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 6,
            move: 2,
            attack: 3,
            range: 4,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 8,
            move: 2,
            attack: 4,
            range: 5,
            attributes: ["Pierce 2", "Shield 2"],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 2,
            attack: 3,
            range: 5,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 6,
            attributes: ["Pierce 2", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 2,
            attack: 4,
            range: 5,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 5,
            range: 6,
            attributes: ["Pierce 3", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 9,
            move: 3,
            attack: 4,
            range: 5,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 6,
            range: 6,
            attributes: ["Pierce 3", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 10,
            move: 3,
            attack: 4,
            range: 6,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 6,
            range: 7,
            attributes: ["Pierce 3", "Shield 3"],
          },
        },
      ],
    },
    "City Guard": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Shield 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Shield 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Shield 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 1", "Shield 2"],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2", "Shield 3"],
          },
        },
        {
          level: 6,
          normal: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3", "Shield 3"],
          },
        },
        {
          level: 7,
          normal: {
            health: 13,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 6,
            range: 0,
            attributes: ["Retaliate 3", "Shield 3"],
          },
        },
      ],
    },
    Cultist: {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Curse"],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Curse"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Curse"],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Curse"],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Curse"],
          },
          elite: {
            health: 22,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Curse"],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Curse"],
          },
          elite: {
            health: 25,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Curse"],
          },
        },
      ],
    },
    "Deep Terror": {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 0,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 5,
            move: 0,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 4,
            move: 0,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 6,
            move: 0,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 4,
            move: 0,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 7,
            move: 0,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 0,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 8,
            move: 0,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 4,
          normal: {
            health: 6,
            move: 0,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 9,
            move: 0,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 7,
            move: 0,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 11,
            move: 0,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 6,
          normal: {
            health: 8,
            move: 0,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 13,
            move: 0,
            attack: 6,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 7,
          normal: {
            health: 9,
            move: 0,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
          elite: {
            health: 15,
            move: 0,
            attack: 6,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
      ],
    },
    "Earth Demon": {
      level: [
        {
          level: 0,
          normal: {
            health: 7,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 9,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 12,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 18,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 13,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Immobilize"],
          },
        },
        {
          level: 4,
          normal: {
            health: 15,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Immobilize"],
          },
        },
        {
          level: 5,
          normal: {
            health: 17,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Immobilize"],
          },
          elite: {
            health: 25,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Immobilize"],
          },
        },
        {
          level: 6,
          normal: {
            health: 20,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Immobilize"],
          },
          elite: {
            health: 27,
            move: 3,
            attack: 6,
            range: 0,
            attributes: ["Immobilize"],
          },
        },
        {
          level: 7,
          normal: {
            health: 22,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Immobilize"],
          },
          elite: {
            health: 32,
            move: 3,
            attack: 6,
            range: 0,
            attributes: ["Immobilize"],
          },
        },
      ],
    },
    "Flame Demon": {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 3"],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 4,
            attributes: ["Flying", "Retaliate 2, Range 2", "Shield 4"],
          },
        },
        {
          level: 2,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Retaliate 3, Range 2", "Shield 4"],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Retaliate 2, Range 2", "Shield 3"],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 3,
            range: 5,
            attributes: ["Flying", "Retaliate 3, Range 3", "Shield 4"],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Retaliate 3, Range 2", "Shield 3"],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 4,
            range: 5,
            attributes: ["Flying", "Retaliate 4, Range 3", "Shield 4"],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Retaliate 3, Range 2", "Shield 4"],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 4,
            range: 5,
            attributes: ["Flying", "Retaliate 4, Range 3", "Shield 5"],
          },
        },
        {
          level: 6,
          normal: {
            health: 4,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Retaliate 4, Range 2", "Shield 4"],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 5,
            range: 5,
            attributes: ["Flying", "Retaliate 5, Range 3", "Shield 5"],
          },
        },
        {
          level: 7,
          normal: {
            health: 5,
            move: 4,
            attack: 4,
            range: 5,
            attributes: ["Flying", "Retaliate 4, Range 3", "Shield 4"],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 5,
            range: 6,
            attributes: ["Flying", "Retaliate 5, Range 4", "Shield 5"],
          },
        },
      ],
    },
    "Frost Demon": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 12,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 18,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 20,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 22,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 25,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
      ],
    },
    "Forest Imp": {
      level: [
        {
          level: 0,
          normal: {
            health: 1,
            move: 3,
            attack: 1,
            range: 3,
            attributes: ["Flying", "Shield 1"],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 1,
            range: 3,
            attributes: ["Flying", "Shield 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 3,
            attack: 1,
            range: 3,
            attributes: ["Flying", "Shield 1"],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 1"],
          },
          elite: {
            health: 6,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Curse", "Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 4,
            attack: 2,
            range: 4,
            attributes: ["Flying", "Shield 1"],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 2,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 1"],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 4,
            attack: 2,
            range: 4,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 2,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 4,
            attack: 2,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 4,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
          elite: {
            health: 9,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 6,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Curse", "Shield 2"],
          },
        },
      ],
    },
    "Giant Viper": {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 2,
            attack: 1,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 3,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 1,
          normal: {
            health: 3,
            move: 2,
            attack: 1,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 2,
          normal: {
            health: 4,
            move: 3,
            attack: 1,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 7,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 3,
          normal: {
            health: 4,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 8,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 4,
          normal: {
            health: 6,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 5,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 13,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 6,
          normal: {
            health: 8,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 7,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 17,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Poison"],
          },
        },
      ],
    },
    "Harrower Infester": {
      level: [
        {
          level: 0,
          normal: {
            health: 6,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 8,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 17,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 4,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 19,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 5,
          normal: {
            health: 12,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 6,
          normal: {
            health: 15,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
          elite: {
            health: 22,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
        {
          level: 7,
          normal: {
            health: 17,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 4"],
          },
          elite: {
            health: 26,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
      ],
    },
    Hound: {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 5,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 4,
            move: 4,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 6,
            move: 5,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 4,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 7,
            move: 5,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 4,
            attack: 2,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 8,
            move: 5,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 11,
            move: 5,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 12,
            move: 5,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 6,
          normal: {
            health: 11,
            move: 5,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 15,
            move: 6,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 5,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 15,
            move: 6,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
      ],
    },
    "Inox Archer": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 2,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 8,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 8,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Wound"],
          },
        },
        {
          level: 5,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 3,
            attack: 4,
            range: 5,
            attributes: ["Wound"],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Wound"],
          },
          elite: {
            health: 19,
            move: 3,
            attack: 5,
            range: 5,
            attributes: ["Wound"],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Wound"],
          },
          elite: {
            health: 23,
            move: 3,
            attack: 5,
            range: 5,
            attributes: ["Wound"],
          },
        },
      ],
    },
    "Inox Guard": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 1,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 8,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 4,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 17,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 3"],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 19,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
        {
          level: 6,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 1"],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
        {
          level: 7,
          normal: {
            health: 19,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Retaliate 2"],
          },
          elite: {
            health: 23,
            move: 3,
            attack: 6,
            range: 0,
            attributes: ["Retaliate 4"],
          },
        },
      ],
    },
    "Inox Shaman": {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 2,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 6,
          normal: {
            health: 15,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 24,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 27,
            move: 4,
            attack: 5,
            range: 4,
            attributes: [],
          },
        },
      ],
    },
    "Living Bones": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 1,
            range: 0,
            attributes: ["Target 2"],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 2,
            range: 0,
            attributes: ["Target 2"],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 3,
            attack: 1,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 2,
            range: 0,
            attributes: ["Target 3", "Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 5,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Target 3", "Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 7,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Target 3", "Shield 1"],
          },
        },
        {
          level: 4,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Target 3", "Shield 1"],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Target 3", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 11,
            move: 6,
            attack: 4,
            range: 0,
            attributes: ["Target 3", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 13,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
          elite: {
            health: 14,
            move: 6,
            attack: 4,
            range: 0,
            attributes: ["Target 3", "Shield 2"],
          },
        },
      ],
    },
    "Living Corpse": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 11,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 2,
            attack: 6,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 21,
            move: 2,
            attack: 6,
            range: 0,
            attributes: ["Poison"],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 25,
            move: 2,
            attack: 6,
            range: 0,
            attributes: ["Poison"],
          },
        },
      ],
    },
    "Living Spirit": {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 2,
            attack: 2,
            range: 2,
            attributes: ["Flying", "Shield 1"],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 2,
            attack: 2,
            range: 2,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 3"],
          },
        },
        {
          level: 2,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 3,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 4,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 4,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 4"],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 4"],
          },
        },
        {
          level: 6,
          normal: {
            health: 4,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 5,
            range: 5,
            attributes: ["Flying", "Shield 4"],
          },
        },
        {
          level: 7,
          normal: {
            health: 6,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 9,
            move: 4,
            attack: 5,
            range: 5,
            attributes: ["Flying", "Shield 4"],
          },
        },
      ],
    },
    Lurker: {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Target 2"],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Shield 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Target 2", "Pierce 1"],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Pierce 1", "Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Target 2", "Pierce 1"],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Pierce 2", "Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Pierce 2"],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Target 2", "Pierce 2", "Shield 1"],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Target 2", "Pierce 2", "Shield 1"],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Target 2", "Pierce 3", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Target 2", "Pierce 2", "Shield 1"],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Target 2", "Pierce 3", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Target 2", "Pierce 3", "Shield 1"],
          },
          elite: {
            health: 16,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Target 2", "Pierce 4", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Target 2", "Pierce 3", "Shield 1"],
          },
          elite: {
            health: 18,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Target 2", "Pierce 4", "Shield 2"],
          },
        },
      ],
    },
    Ooze: {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 1,
            attack: 2,
            range: 2,
            attributes: [],
          },
          elite: {
            health: 8,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 1,
            attack: 2,
            range: 2,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 9,
            move: 1,
            attack: 2,
            range: 3,
            attributes: ["Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 1,
            attack: 2,
            range: 3,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 11,
            move: 1,
            attack: 3,
            range: 3,
            attributes: ["Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 1,
            attack: 3,
            range: 3,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 3,
            range: 4,
            attributes: ["Poison", "Shield 1"],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 2,
            attack: 3,
            range: 3,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 4,
            attributes: ["Poison", "Shield 1"],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 3,
            attributes: ["Poison", "Shield 1"],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Poison", "Shield 1"],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 2,
            attack: 4,
            range: 3,
            attributes: ["Poison", "Shield 1"],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Poison", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 2,
            attack: 4,
            range: 3,
            attributes: ["Poison", "Shield 1"],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 5,
            range: 4,
            attributes: ["Poison", "Shield 2"],
          },
        },
      ],
    },
    "Night Demon": {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 3,
          normal: {
            health: 7,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 13,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 15,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 17,
            move: 5,
            attack: 6,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 21,
            move: 5,
            attack: 6,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 4,
            attack: 6,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
          elite: {
            health: 21,
            move: 5,
            attack: 8,
            range: 0,
            attributes: ["Attackers gain Disadvantage"],
          },
        },
      ],
    },
    "Rending Drake": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 4,
            attack: 3,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 9,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 3,
          normal: {
            health: 7,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 10,
            move: 5,
            attack: 6,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 11,
            move: 6,
            attack: 6,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 14,
            move: 6,
            attack: 6,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 6,
          normal: {
            health: 11,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 15,
            move: 6,
            attack: 7,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 5,
            attack: 5,
            range: 0,
            attributes: ["Wound"],
          },
          elite: {
            health: 18,
            move: 6,
            attack: 7,
            range: 0,
            attributes: ["Wound"],
          },
        },
      ],
    },
    "Savvas Icestorm": {
      level: [
        {
          level: 0,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 3,
            attributes: ["Pierce 3"],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 3,
            range: 4,
            attributes: ["Pierce 3"],
          },
        },
        {
          level: 1,
          normal: {
            health: 10,
            move: 2,
            attack: 2,
            range: 4,
            attributes: ["Pierce 3"],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 3,
            range: 5,
            attributes: ["Pierce 3", "Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 12,
            move: 3,
            attack: 2,
            range: 4,
            attributes: ["Pierce 3"],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 3,
            range: 5,
            attributes: ["Pierce 3", "Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Pierce 3", "Shield 1"],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 4,
            range: 6,
            attributes: ["Pierce 3", "Shield 1"],
          },
        },
        {
          level: 4,
          normal: {
            health: 14,
            move: 3,
            attack: 3,
            range: 5,
            attributes: ["Pierce 3", "Shield 1"],
          },
          elite: {
            health: 19,
            move: 4,
            attack: 4,
            range: 6,
            attributes: ["Pierce 3", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 5,
            attributes: ["Pierce 3", "Shield 1"],
          },
          elite: {
            health: 21,
            move: 4,
            attack: 5,
            range: 6,
            attributes: ["Pierce 3", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 5,
            attributes: ["Pierce 3", "Shield 2"],
          },
          elite: {
            health: 23,
            move: 4,
            attack: 6,
            range: 6,
            attributes: ["Pierce 3", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 17,
            move: 4,
            attack: 4,
            range: 6,
            attributes: ["Pierce 3", "Shield 2"],
          },
          elite: {
            health: 24,
            move: 4,
            attack: 6,
            range: 6,
            attributes: ["Pierce 4", "Shield 3"],
          },
        },
      ],
    },
    "Savvas Lavaflow": {
      level: [
        {
          level: 0,
          normal: {
            health: 8,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 9,
            move: 3,
            attack: 2,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Wound"],
          },
        },
        {
          level: 2,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
        },
        {
          level: 3,
          normal: {
            health: 14,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
        },
        {
          level: 4,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Poison"],
          },
          elite: {
            health: 24,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
        },
        {
          level: 5,
          normal: {
            health: 18,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
          elite: {
            health: 27,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
        },
        {
          level: 6,
          normal: {
            health: 20,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
          elite: {
            health: 30,
            move: 4,
            attack: 6,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
        },
        {
          level: 7,
          normal: {
            health: 24,
            move: 4,
            attack: 4,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
          elite: {
            health: 35,
            move: 4,
            attack: 6,
            range: 0,
            attributes: ["Poison", "Wound"],
          },
        },
      ],
    },
    "Spitting Drake": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying"],
          },
          elite: {
            health: 8,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Flying"],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying"],
          },
          elite: {
            health: 9,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
        },
        {
          level: 2,
          normal: {
            health: 8,
            move: 3,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Muddle"],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 5,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 5,
            range: 5,
            attributes: ["Flying", "Muddle"],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 5,
            range: 5,
            attributes: ["Flying", "Muddle"],
          },
        },
        {
          level: 5,
          normal: {
            health: 12,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
          elite: {
            health: 16,
            move: 4,
            attack: 6,
            range: 5,
            attributes: ["Flying", "Muddle"],
          },
        },
        {
          level: 6,
          normal: {
            health: 13,
            move: 4,
            attack: 5,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
          elite: {
            health: 19,
            move: 4,
            attack: 6,
            range: 5,
            attributes: ["Flying", "Muddle"],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 4,
            attack: 5,
            range: 4,
            attributes: ["Flying", "Muddle"],
          },
          elite: {
            health: 21,
            move: 4,
            attack: 7,
            range: 5,
            attributes: ["Flying", "Muddle"],
          },
        },
      ],
    },
    "Stone Golem": {
      level: [
        {
          level: 0,
          normal: {
            health: 10,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Shield 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 10,
            move: 1,
            attack: 3,
            range: 0,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Shield 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 11,
            move: 1,
            attack: 4,
            range: 0,
            attributes: ["Shield 1"],
          },
          elite: {
            health: 14,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Shield 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 11,
            move: 1,
            attack: 4,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 15,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 4,
          normal: {
            health: 12,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 17,
            move: 2,
            attack: 6,
            range: 0,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 19,
            move: 3,
            attack: 6,
            range: 0,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 6,
          normal: {
            health: 16,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 20,
            move: 3,
            attack: 7,
            range: 0,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 2,
            attack: 5,
            range: 0,
            attributes: ["Shield 3"],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 7,
            range: 0,
            attributes: ["Shield 4"],
          },
        },
      ],
    },
    "Sun Demon": {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 2,
            attack: 2,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
        },
        {
          level: 4,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 1"],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 2"],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 5,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 2"],
          },
          elite: {
            health: 18,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 2"],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 3,
            attack: 4,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 2"],
          },
          elite: {
            health: 22,
            move: 4,
            attack: 5,
            range: 0,
            attributes: ["Flying", "Advantage", "Shield 2"],
          },
        },
      ],
    },
    "Vermling Scout": {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 3,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 5,
          normal: {
            health: 8,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 6,
          normal: {
            health: 9,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 7,
          normal: {
            health: 11,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
      ],
    },
    "Vermling Shaman": {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 2,
            attack: 1,
            range: 3,
            attributes: ["Shield 2"],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Shield 2"],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 2,
            attack: 1,
            range: 3,
            attributes: ["Shield 3"],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 2,
          normal: {
            health: 3,
            move: 2,
            attack: 1,
            range: 4,
            attributes: ["Shield 3"],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 2,
            range: 4,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 2,
            attack: 2,
            range: 4,
            attributes: ["Shield 3"],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Shield 3"],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 4,
            attributes: ["Muddle", "Shield 3"],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Muddle", "Shield 4"],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 3,
            attack: 3,
            range: 4,
            attributes: ["Muddle", "Shield 3"],
          },
          elite: {
            health: 6,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Muddle", "Shield 4"],
          },
        },
        {
          level: 6,
          normal: {
            health: 5,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Muddle", "Shield 3"],
          },
          elite: {
            health: 6,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Muddle", "Shield 5"],
          },
        },
        {
          level: 7,
          normal: {
            health: 7,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Muddle", "Shield 3"],
          },
          elite: {
            health: 8,
            move: 3,
            attack: 4,
            range: 4,
            attributes: ["Muddle", "Shield 5"],
          },
        },
      ],
    },
    "Wind Demon": {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 1"],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 1"],
          },
        },
        {
          level: 1,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 2"],
          },
        },
        {
          level: 2,
          normal: {
            health: 4,
            move: 4,
            attack: 2,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 7,
            move: 5,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 2"],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 4,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 8,
            move: 5,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 2"],
          },
        },
        {
          level: 4,
          normal: {
            health: 7,
            move: 4,
            attack: 3,
            range: 3,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 8,
            move: 5,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Disarm", "Shield 2"],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 2"],
          },
          elite: {
            health: 11,
            move: 5,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Disarm", "Shield 2"],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 12,
            move: 5,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Disarm", "Shield 3"],
          },
        },
        {
          level: 7,
          normal: {
            health: 11,
            move: 4,
            attack: 4,
            range: 4,
            attributes: ["Flying", "Shield 3"],
          },
          elite: {
            health: 13,
            move: 5,
            attack: 5,
            range: 4,
            attributes: ["Flying", "Disarm", "Shield 3"],
          },
        },
      ],
    },
  },
  bosses: {
    "Bandit Commander": {
      level: [
        {
          level: 0,
          health: "8xC",
          move: 3,
          attack: 3,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 1,
          health: "10xC",
          move: 3,
          attack: 3,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 2,
          health: "12xC",
          move: 4,
          attack: 3,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 3,
          health: "13xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 4,
          health: "15xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 5,
          health: "16xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 6,
          health: "19xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
        {
          level: 7,
          health: "23xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: ["Move to next door and reveal room"],
          special2: ["Summon Living Bones"],
          immunities: ["Stun", "Immobilize", "Curse"],
          notes: "",
        },
      ],
    },
    "The Betrayer": {
      level: [
        {
          level: 0,
          health: "10xC",
          move: 3,
          attack: 4,
          range: 3,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 1,
          health: "12xC",
          move: 3,
          attack: 5,
          range: 3,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 2,
          health: "14xC",
          move: 3,
          attack: 6,
          range: 4,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 3,
          health: "16xC",
          move: 4,
          attack: 7,
          range: 4,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 4,
          health: "18xC",
          move: 4,
          attack: 8,
          range: 4,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 5,
          health: "20xC",
          move: 5,
          attack: 8,
          range: 5,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 6,
          health: "23xC",
          move: 5,
          attack: 9,
          range: 5,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
        {
          level: 7,
          health: "27xC",
          move: 5,
          attack: 9,
          range: 5,
          special1: ["Summon Giant Viper and Fear"],
          special2: ["Mind Control"],
          immunities: ["Wound", "Poison", "Stun", "Disarm", "Curse"],
          notes: "",
        },
      ],
    },
    "Captain of the Guard": {
      level: [
        {
          level: 0,
          health: "7xC",
          move: 2,
          attack: 3,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 1,
          health: "9xC",
          move: 2,
          attack: 3,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 2,
          health: "11xC",
          move: 2,
          attack: 4,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 3,
          health: "14xC",
          move: 3,
          attack: 4,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 4,
          health: "16xC",
          move: 3,
          attack: 5,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 5,
          health: "20xC",
          move: 3,
          attack: 5,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 6,
          health: "21xC",
          move: 4,
          attack: 6,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 7,
          health: "25xC",
          move: 4,
          attack: 6,
          range: 0,
          special1: ["Heal 2, Affect self and all allies"],
          special2: [
            "All allies add +1 Attack to all attacks this round",
            "Attack +1",
          ],
          immunities: ["Disarm", "Wound", "Muddle", "Stun"],
          notes: "",
        },
      ],
    },
    "The Colorless": {
      level: [
        {
          level: 0,
          health: "9xC",
          move: 3,
          attack: 2,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 4, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 1,
          health: "10xC",
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 4, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 2,
          health: "11xC",
          move: 4,
          attack: 3,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 5, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 3,
          health: "12xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 5, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 4,
          health: "14xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 6, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 5,
          health: "15xC",
          move: 4,
          attack: 5,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 6, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 6,
          health: "17xC",
          move: 4,
          attack: 6,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 7, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
        {
          level: 7,
          health: "19xC",
          move: 5,
          attack: 7,
          range: 0,
          special1: [
            "{{resources.elements.consumeDark}}: Summon Night Demon",
            "Invisible, Self",
          ],
          special2: [
            "{{resources.elements.consumeLight}}: Summon Sun Demon",
            "Heal 7, Self",
            "Shield 1",
          ],
          immunities: ["Wound", "Poison", "Muddle", "Stun", "Disarm"],
          notes: "",
        },
      ],
    },
    "Dark Rider": {
      level: [
        {
          level: 0,
          health: "9xC",
          move: 2,
          attack: "3+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 1,
          health: "10xC",
          move: 3,
          attack: "3+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 2,
          health: "12xC",
          move: 3,
          attack: "3+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 3,
          health: "13xC",
          move: 3,
          attack: "4+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 4,
          health: "15xC",
          move: 3,
          attack: "4+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 5,
          health: "16xC",
          move: 3,
          attack: "5+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 6,
          health: "16xC",
          move: 4,
          attack: "5+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
        {
          level: 7,
          health: "18xC",
          move: 4,
          attack: "6+X",
          range: 0,
          special1: ["Move +2", "Attack +0"],
          special2: ["Move +0", "Summon Imp", "Attack -1"],
          immunities: ["Immobilize", "Poison", "Stun", "Disarm"],
          notes: "X = Hexes moved",
        },
      ],
    },
    "Elder Drake": {
      level: [
        {
          level: 0,
          health: "11xC",
          move: 0,
          attack: 3,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 1,
          health: "12xC",
          move: 0,
          attack: 4,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 2,
          health: "15xC",
          move: 0,
          attack: 4,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 3,
          health: "16xC",
          move: 0,
          attack: 5,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 4,
          health: "20xC",
          move: 0,
          attack: 5,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 5,
          health: "22xC",
          move: 0,
          attack: 6,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 6,
          health: "27xC",
          move: 0,
          attack: 6,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
        {
          level: 7,
          health: "29xC",
          move: 0,
          attack: 7,
          range: 0,
          special1: ["Attack +0 {{resources.elderDrake.special1Area}}"],
          special2: ["Move and summon 2 Zephyrs"],
          immunities: ["Wound", "Poison", "Disarm", "Immobilize", "Stun"],
          notes: "",
        },
      ],
    },
    "The Gloom": {
      level: [
        {
          level: 0,
          health: "20xC",
          move: 2,
          attack: 5,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 1,
          health: "25xC",
          move: 2,
          attack: 5,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 2,
          health: "29xC",
          move: 2,
          attack: 6,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 3,
          health: "35xC",
          move: 2,
          attack: 6,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 4,
          health: "39xC",
          move: 3,
          attack: 7,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 5,
          health: "46xC",
          move: 3,
          attack: 7,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 6,
          health: "50xC",
          move: 3,
          attack: 8,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
        {
          level: 7,
          health: "56xC",
          move: 3,
          attack: 9,
          range: 0,
          special1: ["Move +9", "Attack +9"],
          special2: [
            "Teleport",
            "Attack +1",
            "Range 5",
            "Poison",
            "Wound",
            "Stun",
          ],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
            "Curse",
          ],
          notes: "",
        },
      ],
    },
    "Inox Bodyguard": {
      level: [
        {
          level: 0,
          health: "6xC",
          move: 2,
          attack: "C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 3"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 1,
          health: "7xC",
          move: 2,
          attack: "1+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 3"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 2,
          health: "9xC",
          move: 2,
          attack: "1+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 3"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 3,
          health: "10xC",
          move: 3,
          attack: "2+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 4"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 4,
          health: "11xC",
          move: 3,
          attack: "2+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 4"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 5,
          health: "13xC",
          move: 3,
          attack: "3+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 5"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 6,
          health: "15xC",
          move: 4,
          attack: "3+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 5"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
        {
          level: 7,
          health: "17xC",
          move: 4,
          attack: "4+C",
          range: 0,
          special1: [
            "Move -1",
            "Attack -1 {{resources.inoxBodyguard.special1Area}}",
          ],
          special2: ["Move +0", "Attack +0", "Retaliate 5"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun"],
          notes: "",
        },
      ],
    },
    Jekserah: {
      level: [
        {
          level: 0,
          health: "6xC",
          move: 2,
          attack: 2,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 1,
          health: "7xC",
          move: 2,
          attack: 3,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 2,
          health: "9xC",
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 3,
          health: "12xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 4,
          health: "13xC",
          move: 4,
          attack: 5,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 5,
          health: "15xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 6,
          health: "18xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
        {
          level: 7,
          health: "22xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            "Summon Living Bones",
            "Attack -1, Target all adjacent enemies",
          ],
          special2: ["Summon Living Corpse", "Move -1", "Attack +2"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "",
        },
      ],
    },
    "Merciless Overseer": {
      level: [
        {
          level: 0,
          health: "6xC",
          move: 2,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 1,
          health: "8xC",
          move: 2,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 2,
          health: "9xC",
          move: 3,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 3,
          health: "11xC",
          move: 3,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 4,
          health: "12xC",
          move: 4,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 5,
          health: "14xC",
          move: 4,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 6,
          health: "16xC",
          move: 4,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
        {
          level: 7,
          health: "19xC",
          move: 4,
          attack: "V",
          range: 0,
          special1: ["All Scouts act again"],
          special2: ["Summon Vermling Scout"],
          immunities: ["Disarm", "Wound", "Curse", "Stun"],
          notes: "V = Number of Scouts present",
        },
      ],
    },
    "Prime Demon": {
      level: [
        {
          level: 0,
          health: "8xC",
          move: 3,
          attack: 4,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 1,
          health: "9xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 2,
          health: "10xC",
          move: 4,
          attack: 5,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 3,
          health: "12xC",
          move: 4,
          attack: 6,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 4,
          health: "14xC",
          move: 5,
          attack: 6,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 5,
          health: "16xC",
          move: 5,
          attack: 7,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 6,
          health: "20xC",
          move: 5,
          attack: 7,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
        {
          level: 7,
          health: "22xC",
          move: 5,
          attack: 8,
          range: 0,
          special1: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          special2: ["Throne moves", "Summon Demon", "Move +2", "Attack -1"],
          immunities: [
            "Wound",
            "Poison",
            "Disarm",
            "Immobilize",
            "Muddle",
            "Stun",
          ],
          notes: "",
        },
      ],
    },
    "The Sightless Eye": {
      level: [
        {
          level: 0,
          health: "7xC",
          move: 0,
          attack: 5,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 1,
          health: "8xC",
          move: 0,
          attack: 6,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 2,
          health: "10xC",
          move: 0,
          attack: 6,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 3,
          health: "11xC",
          move: 0,
          attack: 7,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 4,
          health: "14xC",
          move: 0,
          attack: 7,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 5,
          health: "15xC",
          move: 0,
          attack: 8,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 6,
          health: "18xC",
          move: 0,
          attack: 8,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
        {
          level: 7,
          health: "20xC",
          move: 0,
          attack: 9,
          range: 3,
          special1: [
            "Summon Deep Terror",
            "Attack -3 {{resources.sightlessEye.special1Area}}",
          ],
          special2: [
            "Summon Deep Terror",
            "Attack -2 {{resources.sightlessEye.special2Area}}",
          ],
          immunities: ["Stun", "Disarm", "Curse", "Muddle"],
          notes: "",
        },
      ],
    },
    "Winged Horror": {
      level: [
        {
          level: 0,
          health: "6xC",
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 1,
          health: "7xC",
          move: 4,
          attack: 3,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 2,
          health: "8xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 3,
          health: "10xC",
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 4,
          health: "12xC",
          move: 5,
          attack: 4,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 5,
          health: "14xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 6,
          health: "17xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
        {
          level: 7,
          health: "20xC",
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            "Attack -1, Target all adjacent enemies",
            "Attack +0, Range 3",
            "Hatch eggs",
          ],
          special2: ["Summon C eggs", "Move -1", "Attack +0"],
          immunities: ["Disarm", "Poison", "Muddle", "Stun", "Curse"],
          notes: "",
        },
      ],
    },
  },
}

export default monsterStats
