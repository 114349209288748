import { AttackModifierDeck } from "../models/Deck"
import defaultTheme from "../theme/defaultTheme"

/** Helper */

const generateModifierDeck = (
  kind: GH.Class | "monster",
): AttackModifierDeck => {
  const deck = new AttackModifierDeck({
    id: `${kind}-attack-modifier-deck`,
    icon: kind === "monster" ? "angryFace" : kind,
    color: kind === "monster" ? "black" : defaultTheme.colors.classes[kind],
  })
  switch (kind) {
    case "monster":
      break
    case "scoundrel":
      deck.addExcludedCard({ increment: 0 })
      deck.addExcludedCard({ increment: 1 })
      deck.addExcludedCard({ increment: 2 })
      deck.addExcludedCard({ increment: 2 })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ effect: "pierce", effectCount: 3, rolling: true })
      deck.addExcludedCard({ effect: "pierce", effectCount: 3, rolling: true })
      deck.addExcludedCard({ effect: "poison", rolling: true })
      deck.addExcludedCard({ effect: "poison", rolling: true })
      deck.addExcludedCard({ effect: "poison", rolling: true })
      deck.addExcludedCard({ effect: "poison", rolling: true })
      deck.addExcludedCard({ effect: "muddle", rolling: true })
      deck.addExcludedCard({ effect: "muddle", rolling: true })
      deck.addExcludedCard({ effect: "invisible", rolling: true })
      break
    case "tinkerer":
      deck.addExcludedCard({ increment: 0 })
      deck.addExcludedCard({ increment: 1 })
      deck.addExcludedCard({ increment: 1 })
      deck.addExcludedCard({ increment: 3 })
      deck.addExcludedCard({ effect: "fire", rolling: true })
      deck.addExcludedCard({ effect: "fire", rolling: true })
      deck.addExcludedCard({ effect: "muddle", rolling: true })
      deck.addExcludedCard({ effect: "muddle", rolling: true })
      deck.addExcludedCard({ effect: "muddle", rolling: true })
      deck.addExcludedCard({ increment: 1, effect: "wound" })
      deck.addExcludedCard({ increment: 1, effect: "wound" })
      deck.addExcludedCard({ increment: 1, effect: "immobilize" })
      deck.addExcludedCard({ increment: 1, effect: "immobilize" })
      deck.addExcludedCard({ increment: 1, effect: "heal", effectCount: 2 })
      deck.addExcludedCard({ increment: 1, effect: "heal", effectCount: 2 })
      deck.addExcludedCard({ increment: 0, effect: "addTarget" })
      break
    case "sun":
      deck.addExcludedCard({ increment: 0 })
      deck.addExcludedCard({ increment: 2 })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ increment: 1, rolling: true })
      deck.addExcludedCard({ effect: "heal", rolling: true })
      deck.addExcludedCard({ effect: "heal", rolling: true })
      deck.addExcludedCard({ effect: "heal", rolling: true })
      deck.addExcludedCard({ effect: "heal", rolling: true })
      deck.addExcludedCard({ effect: "stun", rolling: true })
      deck.addExcludedCard({ effect: "light", rolling: true })
      deck.addExcludedCard({ effect: "light", rolling: true })
      deck.addExcludedCard({ effect: "light", rolling: true })
      deck.addExcludedCard({ effect: "light", rolling: true })
      deck.addExcludedCard({ effect: "shield", rolling: true })
      deck.addExcludedCard({ effect: "shield", rolling: true })
      deck.addExcludedCard({ increment: 1 })
      deck.addExcludedCard({ increment: 1 })
      break
    default:
      throw new Error("Unknown kind")
  }
  return deck
}

/** Exports */

export default generateModifierDeck
