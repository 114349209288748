import React, { ComponentProps } from "react"
import SVG from "react-inlinesvg"
import addMinus1 from "../assets/icons/add-minus1.svg"
import aoe3Triangle from "../assets/icons/areasOfAttack/aoe-3-triangle.svg"
import aoe7Hex from "../assets/icons/areasOfAttack/aoe-7-hex.svg"
import melee3Line from "../assets/icons/areasOfAttack/melee-3-line.svg"
import melee3Triangle from "../assets/icons/areasOfAttack/melee-3-triangle.svg"
import melee6Triangle from "../assets/icons/areasOfAttack/melee-6-triangle.svg"
import melee7HexSide3Blue from "../assets/icons/areasOfAttack/melee-7-hex-side-3-blue.svg"
import attackHex from "../assets/icons/attack-hex.svg"
import attack from "../assets/icons/attack.svg"
import blueHex from "../assets/icons/blue-hex.svg"
import cannotRecover from "../assets/icons/cannot-recover.svg"
import cardSlot from "../assets/icons/card-slot.svg"
import check from "../assets/icons/check.svg"
import angryFace from "../assets/icons/classes/angry-face.svg"
import brute from "../assets/icons/classes/brute.svg"
import circles from "../assets/icons/classes/circles.svg"
import cragheart from "../assets/icons/classes/cragheart.svg"
import cthulhu from "../assets/icons/classes/cthulhu.svg"
import eclipse from "../assets/icons/classes/eclipse.svg"
import lightningBolts from "../assets/icons/classes/lightning-bolts.svg"
import mindthief from "../assets/icons/classes/mindthief.svg"
import musicNotes from "../assets/icons/classes/music-notes.svg"
import saw from "../assets/icons/classes/saw.svg"
import scoundrel from "../assets/icons/classes/scoundrel.svg"
import spellweaver from "../assets/icons/classes/spellweaver.svg"
import sun from "../assets/icons/classes/sun.svg"
import threeSpears from "../assets/icons/classes/three-spears.svg"
import tinkerer from "../assets/icons/classes/tinkerer.svg"
import triforce from "../assets/icons/classes/triforce.svg"
import twoMinis from "../assets/icons/classes/two-minis.svg"
import consumeDark from "../assets/icons/elements/consume-dark.svg"
import consumeElement from "../assets/icons/elements/consume-element.svg"
import consumeFire from "../assets/icons/elements/consume-fire.svg"
import consumeIce from "../assets/icons/elements/consume-ice.svg"
import consumeLeaf from "../assets/icons/elements/consume-leaf.svg"
import consumeLight from "../assets/icons/elements/consume-light.svg"
import consumeWildElement from "../assets/icons/elements/consume-wild-element.svg"
import consumeWind from "../assets/icons/elements/consume-wind.svg"
import dark from "../assets/icons/elements/dark.svg"
import fire from "../assets/icons/elements/fire.svg"
import ice from "../assets/icons/elements/ice.svg"
import leaf from "../assets/icons/elements/leaf.svg"
import light from "../assets/icons/elements/light.svg"
import wind from "../assets/icons/elements/wind.svg"
import fly from "../assets/icons/fly.svg"
import heal from "../assets/icons/heal.svg"
import jump from "../assets/icons/jump.svg"
import loot from "../assets/icons/loot.svg"
import lost from "../assets/icons/lost.svg"
import move from "../assets/icons/move.svg"
import persistentBonus from "../assets/icons/persistent-bonus.svg"
import playerHex from "../assets/icons/player-hex.svg"
import range from "../assets/icons/range.svg"
import refreshItem from "../assets/icons/refresh-item.svg"
import retaliate from "../assets/icons/retaliate.svg"
import roundBonus from "../assets/icons/round-bonus.svg"
import shield from "../assets/icons/shield.svg"
import shuffle from "../assets/icons/shuffle.svg"
import spendItem from "../assets/icons/spend-item.svg"
import addTarget from "../assets/icons/statusEffects/add-target.svg"
import bless from "../assets/icons/statusEffects/bless.svg"
import curse from "../assets/icons/statusEffects/curse.svg"
import disarm from "../assets/icons/statusEffects/disarm.svg"
import immobilize from "../assets/icons/statusEffects/immobilize.svg"
import invisible from "../assets/icons/statusEffects/invisible.svg"
import muddle from "../assets/icons/statusEffects/muddle.svg"
import pierce from "../assets/icons/statusEffects/pierce.svg"
import poison from "../assets/icons/statusEffects/poison.svg"
import pull from "../assets/icons/statusEffects/pull.svg"
import push from "../assets/icons/statusEffects/push.svg"
import roll from "../assets/icons/statusEffects/roll.svg"
import strengthen from "../assets/icons/statusEffects/strengthen.svg"
import stun from "../assets/icons/statusEffects/stun.svg"
import wound from "../assets/icons/statusEffects/wound.svg"
import target from "../assets/icons/target.svg"
import useSlotGainXp from "../assets/icons/use-slot-gain-xp.svg"
import useSlot from "../assets/icons/use-slot.svg"
import xp from "../assets/icons/xp.svg"

/** Constants */

const icons = {
  addMinus1,
  addTarget,
  angryFace,
  aoe3Triangle,
  aoe7Hex,
  attack,
  disarm,
  melee7HexSide3Blue,
  attackHex,
  bless,
  brute,
  cannotRecover,
  cardSlot,
  check,
  circles,
  consumeDark,
  consumeElement,
  consumeFire,
  consumeIce,
  consumeLeaf,
  consumeLight,
  consumeWildElement,
  consumeWind,
  melee3Triangle,
  cragheart,
  cthulhu,
  curse,
  dark,
  eclipse,
  fire,
  fly,
  heal,
  ice,
  immobilize,
  invisible,
  blueHex,
  jump,
  leaf,
  light,
  lightningBolts,
  loot,
  lost,
  mindthief,
  move,
  muddle,
  musicNotes,
  persistentBonus,
  pierce,
  playerHex,
  poison,
  pull,
  push,
  range,
  refreshItem,
  retaliate,
  roll,
  roundBonus,
  saw,
  scoundrel,
  shield,
  shuffle,
  spellweaver,
  spendItem,
  strengthen,
  stun,
  sun,
  target,
  threeSpears,
  melee6Triangle,
  tinkerer,
  triforce,
  twoMinis,
  useSlot,
  useSlotGainXp,
  wind,
  wound,
  xp,
  melee3Line,
}

/** Types */

type IconType = keyof typeof icons

/** Component */

const Icon = ({
  type,
  ...props
}: Omit<ComponentProps<typeof SVG>, "src" | "type"> & {
  type: keyof typeof icons
}) => (
  /* @ts-ignore */
  <SVG {...props} src={icons[type]} />
)

/** Exports */

export default Icon
export type { IconType }
export { icons }
