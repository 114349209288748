import deepmerge from "deepmerge"
import { DefaultTheme } from "styled-components"
import defaultTheme from "./defaultTheme"

/** Theme */

const gloomy: DefaultTheme = deepmerge(defaultTheme, {})

/** Exports */

export default gloomy
