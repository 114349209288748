import { IconType } from "../../components/Icon"
import Card from "./Card"

/** Class */

class AttackModifierCard extends Card {
  /** The amount by which to modify the result by summing. */
  increment: number
  /** The amount by which to multiply the result. */
  multiplier: number
  /** Whether or not the card is a "rolling" modifier. */
  rolling: boolean
  /** Whether or not the card will be removed from the deck after it's drawn. */
  temporary: boolean
  /** An effect that should be triggered by the card. */
  effect?: IconType
  /** The number of times that the effect should be triggered. Defaults to 1 if
   *  an effect is provided but not an effect count. */
  effectCount: number
  /** Whether or not this is a card that started out in the deck. */
  startingCard = false

  constructor({
    increment = 0,
    multiplier = 1,
    rolling = false,
    id,
    text,
    triggersShuffle = false,
    temporary = false,
    color,
    icon,
    effect,
    effectCount = 0,
    startingCard = false,
  }: Partial<AttackModifierCard> &
    Pick<AttackModifierCard, "id" | "color" | "icon">) {
    super({ id, text, triggersShuffle, color, icon })
    this.increment = increment
    this.multiplier = multiplier
    this.rolling = rolling
    this.temporary = temporary
    this.effect = effect
    this.effectCount = !!effect ? effectCount || 1 : effectCount
    this.startingCard = startingCard
  }

  save() {
    return {
      ...super.save(),
      increment: this.increment,
      multiplier: this.multiplier,
      rolling: this.rolling,
      temporary: this.temporary,
      effect: this.effect,
      effectCount: this.effectCount,
      startingCard: this.startingCard,
    }
  }

  static load(card: ReturnType<AttackModifierCard["save"]>) {
    return new AttackModifierCard(card)
  }
}

/** Exports */

export default AttackModifierCard
