import { monsterAbilities } from "../data"
import MonsterAbilityDeck from "../models/Deck/MonsterAbilityDeck"

/** Helper */

const generateMonsterDeck = (kind: GH.Monster): MonsterAbilityDeck =>
  new MonsterAbilityDeck({
    id: kind.toLowerCase().replace(" ", "-"),
    name: kind,
    cards: monsterAbilities[kind],
  })

/** Exports */

export default generateMonsterDeck
