import { motion, MotionProps } from "framer-motion"
import React, { ReactNode } from "react"
import styled from "styled-components"
import CardModel from "../models/Card"

/** Types */

type Props = {
  /** The card that should be rendered. */
  card: CardModel
  /** Callback for when a card is clicked. */
  onClick?: () => void
  /** The front and back of the card, in that order. */
  children: [ReactNode, ReactNode]
} & MotionProps

/** Constants */

/** Component */

const Card = ({ card, onClick, children, ...motionProps }: Props) => (
  <Frame onClick={() => onClick?.()} {...motionProps}>
    {children}
  </Frame>
)

/** GraphQL */

/** Styles */

const Frame = styled(motion.div)`
  width: 96px;
  height: 68px;
  min-width: 68px;
  transform-style: preserve-3d;

  & > div {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backface-visibility: hidden;
    overflow: hidden;
  }

  & > div:last-child {
    transform: rotateY(180deg);
  }
`

/** Hooks */

/** Helpers */

/** Exports */

export default Card
